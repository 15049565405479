<template>
  <ion-page id="rewardDetail">
    <ion-content :fullscreen="true">
      <topbar class="gradient30 fixed" :bigtitle="false" :showBack="true" :showClose="false" :share="true" :shareData="shareData"></topbar>

      <div class="content">
        <div class="coupon-img">
          <img v-lazy="rewardDetail.image" :key="rewardDetail.image" />
        </div>

        <div class="coupon-info">
          <div class="coupon-name main-color font-24 Bold">
            <span>{{ rewardDetail.name }}</span>
          </div>
        </div>
        <div class="tandC">
          <div class="title Bold">Rewards details</div>
          <showMore :content="rewardDetail.descr" :showHeight="!$store.state.isPC ? 70 : 68"></showMore>
        </div>

        <div class="hr"></div>
        <div class="tandC">
          <div class="title Bold">Rewards details</div>
          <div class="ruleBox">
            <div class="item-table" style="width: 100%; padding: 20px">
              <div class="tdcell" style="white-space: nowrap">
                <span class="font-24 Bold main-color">{{ rewardDetail.stampQty }} </span>
                <span class="font-14 Bold main-color"> stamps</span>
                <span class="cash font-14" v-show="rewardDetail.cashQty">+${{ rewardDetail.cashQty }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="height50"></div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from "@ionic/vue";
import config from "../config";
import topbar from "@/components/topbar.vue";
import showMore from "@/components/showMore.vue";
export default {
  name: "rewardDetail",
  mixins: [config.globalMixin],
  data() {
    return {
      rewardDetail: {},
      size: 150,
      shareData: {},
    };
  },
  components: { IonContent, IonPage, topbar, showMore },
  methods: {
    showMore(type) {
      this.$refs[type].style.height = "auto";
    },
  },
  ionViewWillEnter() {
    this.rewardDetail.cashQty = this.$route.query.cashQty;
    this.rewardDetail.image = this.$route.query.giftImage;
    this.rewardDetail.name = this.$route.query.giftName;
    this.rewardDetail.redeemCode = this.$route.query.redeemCode;
    this.rewardDetail.redeemTime = this.$route.query.redeemTime;
    this.rewardDetail.stampQty = this.$route.query.stampQty;
    this.rewardDetail.descr = this.$route.query.giftDescr;
    this.shareData = {
      title: this.$store.state.appName + " - Easy peasy! Redeem reward now!",
      description: `${this.rewardDetail.name}
        Redemption Period: ${this.$route.query.redemption}`,
      image: this.rewardDetail.image,
    };
    this.$store.state.exitApp = false;
    this.sendGAPageView("Reward Redeemed Detail");
  },
};
</script>
